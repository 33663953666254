import React, { useReducer, useEffect } from "react";
import EssityContext from "./EssityContext";
import EssityReducer from "./EssityReducer";
import axios from "axios";
import jwtdeocde from "jwt-decode";

import {
  GET_CLIENT,
  LOGIN_USER,
  ALL_STOCK,
  ALL_CATEGORIES,
  ALL_PRODUCTS,
  CART_ITEMS,
  CART_ITEM,
  TOTAL,
  DELETE_ITEM,
  GET_ORDERS,
  ERROR,
  MSG,
  SET_LOADING,
  DEL_CARD,
  SET_LOADINGFALSE,
  PRODUCTMANAGER,
} from "./types";
const EssityState = (props) => {




  const initialState = {
    error: {},
    user: {},
    clients: {},
    stock: [],
    categories: [],
    products: [],
    product: {},
    cardItem: [],
    grandtotal: 0,
    orders: [],
    msg: "",
    loading: false,
    productManager: [],
    categoryPagination: {},
    categorySkuPagination: {},
    orderPagination: {},
    stockReportPagination: {},
    productManagerWithWarehouse: []
  };
  const [state, dispatch] = useReducer(EssityReducer, initialState);
  useEffect(() => {
    try {
      if (localStorage.getItem("user")) {
        const user = JSON.parse(localStorage.getItem("user"));
        Dispatch(LOGIN_USER, user);
      }
      if (localStorage.getItem("token")) {
        setAuthTokenAdmin(localStorage.token);
      }
    } catch (error) {
      console.log("error during set user", error);
    }
  }, []);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const setAuthTokenAdmin = (token) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  };



  axios.interceptors.request.use((config) => {
    config.headers['site'] = 'internalsite';
    const currentUrl = window.location.pathname;
    const clientName = currentUrl?.replace(/^\/|\/$/g, '')?.split('/')?.[0];
    config.headers['clientname'] = clientName;
    return config;
  }, (error) => {
    console.log(error);
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (602 === error?.response?.status) {
        console.log("Error response data:", error?.response?.data?.clientName);
        window.location.href = `${process.env.REACT_APP_MAIN_APP_URL}/${error?.response?.data?.clientName}`;
        return Promise.reject(error);
      }
      // 🚀 Ensure the error is properly rejected so the catch block works!
      return Promise.reject(error);
    }
  );



  /**
   *
   * @param {*} USER
   */

  const loginUser = async (
    login,
    password,
    isTermAccepted,
    history,
    clientName,
    clients,
    match,
    delivery
  ) => {
    const info = {
      login: login,
      password: password,
      client: clientName,
      isTermAccepted,
    };
    try {
      if (delivery === "delivery") {
        let userResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/login`,
          info,
          config
        );
        if (userResponse.data?.user?.userstatus === "active") {
          const { token, user } = userResponse?.data;
          localStorage.setItem("token", token);
          setUserDataInLocalStorageAndState(user);
          if (localStorage.getItem("token")) {
            setAuthTokenAdmin(localStorage.token);
          }
          removeError();
          localStorage.removeItem("isGuestMode");
          History(history, `/${match.params.essity}/delivery`);
        } else {
          Dispatch(ERROR, {
            password:
              "Log in credentials do not match those of an active user – please contact your focal point",
          });
        }
      } else {
        let userResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/login`,
          info,
          config
        );
        if (userResponse.data?.user?.userstatus === "active") {
          const { token, user } = userResponse.data;
          localStorage.setItem("token", token);
          localStorage.setItem("clientname", match.params.essity);
          setUserDataInLocalStorageAndState(user);
          if (localStorage.getItem("token")) {
            setAuthTokenAdmin(localStorage.token);
          }
          removeError();
          setMsg(`${user.name} you are logged in !!! `);
          timeOut();
          localStorage.removeItem("isGuestMode");
          History(history, `/${match.params.essity}/dashboard`);
        } else {
          Dispatch(ERROR, {
            password:
              "Log in credentials do not match those of an active user – please contact your focal point",
          });
        }
      }
    } catch (err) {
      Dispatch(ERROR, err?.response?.data?.err);
    }
  };
  const setCurrLoggedUser = async (decode) => {
    setLoading();
    if (decode) {
      Dispatch(LOGIN_USER, {});
    } else {
      if (localStorage.getItem("token")) {
        let token = localStorage.getItem("token");
        let decode = jwtdeocde(token);
        try {
          let res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/user/${decode.id}`
          );
          Dispatch(LOGIN_USER, res.data.data);
        } catch (err) { }
      }
    }
  };
  const updateUser = async (data, id, clientName, history, match) => {
    try {
      let res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/user/${id}`,
        data,
        config
      );
      removeError();
      setMsg(`${res.data.data.name} is updated successfully !!`);
      History(history, `/${match.params.essity}/dashboard`);
      setUserDataInLocalStorageAndState(res.data?.data);
      timeOut();
    } catch (err) {
      Dispatch(ERROR, err?.response?.data?.err);
    }
  };
  const setUserDataInLocalStorageAndState = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    Dispatch(LOGIN_USER, user);
  };
  const setClientDataInLocalStorageAndState = (client) => {
    localStorage.setItem("client", JSON.stringify(client));
    Dispatch(GET_CLIENT, client);
  };
  const logoutUser = () => {
    let keysToRemove = [
      "token",
      "currentCategory",
      "orders",
      "card",
      "paymenttotal",
      "total",
      "user",
      "isGuestMode",
    ];
    keysToRemove.forEach((k) => localStorage.removeItem(k));
    Dispatch(DEL_CARD);
    clearMsg();
    Dispatch(LOGIN_USER, {});
  };

  /**
   *
   * @param {*} CLIENTS
   */

  const checkUserAlreadyLogged = async () => {
    try {
      if (localStorage.getItem("token")) {

        setAuthTokenAdmin(localStorage.token);

        let userTokenResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/user/getUserByToken`,
          config
        );
        setUserDataInLocalStorageAndState(userTokenResponse.data.user);
        localStorage.setItem(
          "client",
          JSON.stringify(userTokenResponse.data.client)
        );
        initialState.client = userTokenResponse.data.client;

      }
    } catch (error) {
      // setUserDataInLocalStorageAndState({});
    }
  };


  const setCurrentClientUpdateData = (clientname, history) => {

    setTimeout(async () => {
      try {
        let clientResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/client/find/${clientname}`,
          config
        );
        if (
          localStorage.getItem("user") &&
          localStorage.getItem("token") &&
          localStorage.getItem("clientname") &&
          localStorage.getItem("clientname") !== clientname
        ) {
          Dispatch(GET_CLIENT, JSON.parse(localStorage.getItem("client")));
          history.push(`/${localStorage.getItem("clientname")}/dashboard`);
        } else if (
          localStorage.getItem("user") &&
          localStorage.getItem("token") &&
          localStorage.getItem("clientname") === clientname
        ) {
          localStorage.setItem("clientname", clientname);
          setClientDataInLocalStorageAndState(clientResponse.data.data);
        } else if (
          !localStorage.getItem("user") &&
          !localStorage.getItem("token")
        ) {
          localStorage.setItem("clientname", clientname);
          setClientDataInLocalStorageAndState(clientResponse.data.data);
          if (
            !localStorage.getItem("isGuestMode") ||
            localStorage.getItem("isGuestMode") === false
          ) {
            history.push(`/${clientname}`);
          }
        }
      } catch (error) {
        if (localStorage.getItem("token")) {
          let token = localStorage.getItem("token");
          let decode = jwtdeocde(token);
          try {
            let userResponse = await axios.get(
              `${process.env.REACT_APP_BACKEND_URL}/user/${decode.id}`
            );
            Dispatch(GET_CLIENT, JSON.parse(localStorage.getItem("client")));
            Dispatch(LOGIN_USER, userResponse.data.data);
            History(
              history,
              `/${localStorage.getItem("clientname")}/dashboard`
            );
          } catch (err) {
            History(history, `/${localStorage.getItem("clientname")}`);
          }
        } else {

          History(history, "/notfound");
        }
      }
    }, 300);
  };

  const getClients = async (params, history) => {

    if (localStorage.getItem("clientname")) {
      if (localStorage.getItem("clientname") === params) {
        localStorage.setItem("clientname", params);
        try {
          let res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/client/find/${params}`,
            config
          );
          if (res.data.data.status === "active") {
            localStorage.setItem("client", JSON.stringify(res.data.data));
          }
          Dispatch(GET_CLIENT, res.data.data);
        } catch (err) {
          History(history, "/notfound");
        }
      } else {
        try {
          let res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/client/find/${localStorage.getItem("clientname")}`,
            config
          );
          if (res.data.data.status === "active") {
            localStorage.setItem("client", JSON.stringify(res.data.data));
          }
          History(history, localStorage.getItem("clientname"));
          Dispatch(GET_CLIENT, res.data.data);
        } catch (err) {
          if (localStorage.getItem("token")) {
            let token = localStorage.getItem("token");
            let decode = jwtdeocde(token);
            try {
              let userResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/user/${decode.id}`
              );
              Dispatch(LOGIN_USER, userResponse.data.data);
              History(
                history,
                `/${localStorage.getItem("clientname")}/dashboard`
              );
            } catch (err) {
              History(history, `/${localStorage.getItem("clientname")}`);
            }
          } else {
            History(history, "/notfound");
          }
        }
      }
    } else {
      try {
        let res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/client/find/${params}`,
          config
        );
        localStorage.setItem("clientname", params);
        if (res.data.data.status === "active") {
          localStorage.setItem("client", JSON.stringify(res.data.data));
        }
        Dispatch(GET_CLIENT, res.data.data);
      } catch (err) {
        History(history, "/notfound");
      }
    }
  };

  /**
   *
   * @param {*} STOCK
   */

  const allStocks = async (
    clientWarehouse,
    limit,
    page,
    searchField,
    searchValue,
    dateFrom,
    dateTo,
    sortBy,
    orderBy
  ) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthTokenAdmin(token);
      try {
        setLoading();
        const stockResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/stock/allstock/${clientWarehouse}/?limit=${limit}&page=${page}&searchField=${searchField}&searchValue=${searchValue}&dateFrom=${dateFrom}&dateTo=${dateTo}&sortBy=${sortBy}&orderBy=${orderBy}`
        );
        Dispatch(ALL_STOCK, stockResponse.data.data);
      } catch (err) { }
    }
  };
  const clearStock = () => {
    Dispatch(ALL_STOCK, []);
  };

  /**
   *
   * @param {*} CATEGORIES
   */

  const getAllCategories = async (
    // clientName,
    // searchCategoryValue,
    limit,
    page,
    client
  ) => {
    setLoading();
    try {
      // let res = await axios.get(
      //   `${process.env.REACT_APP_BACKEND_URL}/client/find/${clientName}`,
      //   config
      // );

      let clientData = client;
      if (clientData?.isCategoryEnabled) {
        // console.log("searchCategoryValue", searchCategoryValue);
        let res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/category/get/${client._id}/?limit=${limit}&page=${page}`
          // &searchValue=${searchCategoryValue}`
        );
        Dispatch(ALL_CATEGORIES, res?.data);
      } else {
        Dispatch(ALL_CATEGORIES, []);
      }
    } catch (err) { }
  };
  const clearAllCategories = () => {
    Dispatch(ALL_CATEGORIES, []);
  };

  const getAllProducts = async (
    categoryId,
    client,
    limit,
    page,
    searchField,
    searchValue,
    sortBy,
    orderBy
  ) => {
    if (client?.isCategoryEnabled) {
      setTimeout(async () => {
        try {
          setLoading();
          const categoryResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/category/getSingleCategory/${categoryId}?limit=${limit}&page=${page}&searchField=${searchField}&searchValue=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}&clientId=${client?.id}`
          );
          if (categoryResponse?.data?.skus?.length !== 0) {
            Dispatch(ALL_PRODUCTS, categoryResponse?.data);
          } else {
            Dispatch(ALL_PRODUCTS, []);
          }
        } catch (err) { }
      }, 1);
    } else {
      setTimeout(async () => {
        try {
          setLoading();
          const categoryResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product/getAllProducts/?limit=${limit}&page=${page}&clientId=${client?.id}&searchField=${searchField}&searchValue=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}`);
          if (categoryResponse?.data?.skus?.length !== 0) {
            Dispatch(ALL_PRODUCTS, categoryResponse?.data);
          } else {
            Dispatch(ALL_PRODUCTS, []);
          }
        } catch (err) { }
      }, 1);
    }
  };
  const clearAllProducts = () => {
    Dispatch(ALL_PRODUCTS, []);
  };

  /**
   *
   * @param {*} CARD
   */

  const cardItems = (data, user, quantity, userAddDescription) => {
    let {
      description,
      unitPrice,
      _id,
      id,
      imageURL,
      unit,
      currentStock,
      productManager,
      taxGroup,
      unitPriceForCreatingAnOrder
    } = data;
    let totalPrice = (parseFloat(unitPrice) * quantity).toFixed(2);
    let item = {
      id: _id || Math.random(),
      name: description,
      unitPrice: parseFloat(unitPrice),
      unit,
      code: id,
      description: userAddDescription ? userAddDescription : "",
      imageURL: imageURL[0],
      currentStock,
      productManager,
      taxGroup,
      unitPriceForCreatingAnOrder
    };
    // let findIndex = state.cardItem.findIndex((c) => c.id === _id);
    let findIndex = state.cardItem.findIndex((c) => c.code === id);
    if (findIndex >= 0) {
      let currentItem = state.cardItem[findIndex];
      item.totalPrice = (parseFloat(currentItem.totalPrice) + parseFloat(totalPrice)).toFixed(2);
      item.quantity = currentItem.quantity + parseInt(quantity);
      state.cardItem[findIndex] = item;
      Dispatch(CART_ITEM, state.cardItem);
    } else {
      item.totalPrice = parseFloat(totalPrice);
      item.quantity = parseInt(quantity);
      Dispatch(CART_ITEMS, item);
    }
  };

  const setCardItem = async () => {
    if (localStorage.card) {
      Dispatch(CART_ITEM, JSON.parse(localStorage.getItem("card")));
    }
  };

  const clearCardItem = async () => {
    Dispatch(CART_ITEM, []);
  };

  const removeItem = (id) => {
    let o = 0;
    localStorage.setItem("card", JSON.stringify(state.cardItem));
    state.cardItem.map((item) => {
      o += item.totalPrice;
    });
    localStorage.setItem("total", o);
    grandTotal();
    Dispatch(DELETE_ITEM, id);
  };

  const grandTotal = () => {
    if (localStorage.total) {
      Dispatch(TOTAL, localStorage.getItem("total"));
    }
  };

  /**
   *
   * @param {*} ORDERS
   */

  const getOrders = async (limit, page) => {
    const token = localStorage.getItem("token");
    setLoading();
    if (token) {
      setAuthTokenAdmin(token);
      try {
        if (localStorage.client) {
          const client = JSON.parse(localStorage.client);
          let orderResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/order/getOrderByClientId/${client._id}/?limit=${limit}&page=${page}`,
            config
          );
          localStorage.setItem(
            "orders",
            JSON.stringify(orderResponse.data.data)
          );
          Dispatch(GET_ORDERS, orderResponse.data.data);
        }
      } catch (err) { }
    }
  };

  const addOrder = async (
    data,
    id,
    history,
    match,
    clientPayment,
    userTermAccepted
  ) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthTokenAdmin(token);
      try {
        setLoading();

        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/order/addorder/${id}`,
          data,
          config
        );
        state.loading = false;
        setMsg("your order is created successfully !!!!");
        if (!userTermAccepted) {
          let userData = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/user/${id}`,
            config
          );
          setUserDataInLocalStorageAndState(userData.data?.data);
        }

        localStorage.removeItem("card");
        localStorage.removeItem("total");
        Dispatch(DEL_CARD);
        History(history, `/${match.params.essity}/dashboard`);
        setTimeout(() => {
          clearMsg();
        }, 6000);

        removeError();
      } catch (err) {
        Dispatch(ERROR, err.response.data.err);
      }
    }
  };

  const updateOrder = async (data, id, history, clientName) => {
    try {
      setLoading();
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/order/updateOrderByApprover/${id}`,
        data,
        config
      );
      setLoadingFalse();
      setMsg(
        "Order is updated successfully, Will process soon after other Managers approve their Items"
      );
      History(history, `/${clientName}/orderreview`);
      timeOut();
    } catch (err) { }
  };
  const getProductManager = async () => {
    if (localStorage.token) {
      setAuthTokenAdmin(localStorage.token);
      try {
        setLoading();
        let res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/user/getProductManager`,
          config
        );
        Dispatch(PRODUCTMANAGER, res.data.data);
        setLoadingFalse();
        return res.data.data;
      } catch (err) { }
    }
  };

  const getProductManagerWithCLientWarehouse = async (clientWarehouse) => {
    if (localStorage.token) {
      setAuthTokenAdmin(localStorage.token);
      try {
        // setLoading();
        let res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/user/getProductManager`,
          config
        );
        let currentWarehouseMangers = res?.data?.data?.filter(
          (x) => x.warehouse === clientWarehouse
        );
        currentWarehouseMangers = currentWarehouseMangers?.map(
          (x) => x.productManager
        );
        // setLoadingFalse();
        Dispatch("PRODUCTMANAGERWITHWAREHOUSE", currentWarehouseMangers);
      } catch (err) { }
    }
  };


  const clearOrder = () => {
    Dispatch(GET_ORDERS, []);
  };

  const cancelOrder = (history, match) => {
    Dispatch(DEL_CARD);
    localStorage.removeItem("total");
    localStorage.removeItem("card");
    History(history, `/${match.params.essity}/dashboard`);
  };

  /**
   *
   * @param {*} STRIPE
   */

  const StripePayment = async (cardElement, stripe, history, match, id) => {
    try {
      let result = await stripe.createToken(cardElement);
      if (result.error) {
        Dispatch(ERROR, result.error);
      }
      if (result.token) {
        removeError();
        payment(result, localStorage.getItem("total"), history, match, id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const payment = async (token, total, history, match, id) => {
    try {
      if (parseInt(total) !== 0) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/checkout`,
          { token, total },
          config
        );
        History(history, `/${match.params.essity}/delivery`);
      } else {
        History(history, `/${match.params.essity}/delivery`);
      }
    } catch (err) {
      Dispatch(ERROR, err.response.data.err);
    }
  };
  /**
   *
   * @param {*} UTILS
   */

  const removeError = () => {
    Dispatch(ERROR, {});
  };

  const setLoading = () => {
    Dispatch(SET_LOADING);
  };
  const setMsg = (msg) => {
    Dispatch(MSG, msg);
  };
  const clearMsg = () => {
    Dispatch(MSG, "");
  };
  const History = (history, pathname, msg) => {
    history.push({
      pathname: pathname,
      state:
        JSON.stringify(msg) !== JSON.stringify({}) && msg !== undefined
          ? msg
          : "",
    });
  };
  const Dispatch = (type, data) => {
    dispatch({
      type: type,
      payload: data,
    });
  };
  const timeOut = () => {
    setTimeout(() => {
      clearMsg();
    }, 10000);
  };
  const setLoadingFalse = () => {
    Dispatch(SET_LOADINGFALSE);
  };

  const syncOrder = async (id) => {
    if (localStorage.token) {
      setAuthTokenAdmin(localStorage.token);
      try {
        setLoading();
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/order/syncOrder/${id}`
        );
        setMsg("Order is Sync successfully!!");
        setLoadingFalse();
        timeOut();
      } catch (err) {
        setLoadingFalse();
        setMsg("Order is Not Sync successfully!!");
      }
    }
  };



  const clearAllPaginationState = () => {
    Dispatch("CATEGORYPAGINATION", { ...state.categoryPagination, page: "", });
    Dispatch("CATEGORYSKUPAGINATION", { ...state.categorySkuPagination, page: "", searchField: "", searchValue: "" })
    Dispatch("ORDERPAGINATION", { ...state?.orderPagination, page: "" })
    Dispatch("STOCKREPORTPAGINATION", { ...state.stockReportPagination, page: "", searchField: "", searchValue: "" })
  }


  const categoryPaginationAndSearchingState = async (page, limit) => {
    Dispatch("CATEGORYPAGINATION", { page, limit })
  }

  const categorySkuPaginationAndSearchingState = async (page, limit, searchField, searchValue) => {
    Dispatch("CATEGORYSKUPAGINATION", { page, limit, searchField, searchValue })
  }

  const orderPaginationAndSearchingState = async (page, limit) => {
    Dispatch("ORDERPAGINATION", { page, limit })
  }

  const stocReportPaginationAndSearchingState = async (page, limit, searchField, searchValue) => {
    Dispatch("STOCKREPORTPAGINATION", { page, limit, searchField, searchValue })
  }

  return (
    <EssityContext.Provider
      value={{
        loginUser,
        getProductManager,
        getClients,
        setCurrLoggedUser,
        checkUserAlreadyLogged,
        setCurrentClientUpdateData,
        updateUser,
        allStocks,
        getAllCategories,
        getAllProducts,
        cardItems,
        setCardItem,
        grandTotal,
        removeItem,
        getOrders,
        addOrder,
        updateOrder,
        payment,
        removeError,
        clearMsg,
        setMsg,
        setLoading,
        logoutUser,
        StripePayment,
        clearAllProducts,
        clearStock,
        clearOrder,
        clearAllCategories,
        cancelOrder,
        syncOrder,
        clearCardItem,
        categoryPaginationAndSearchingState,
        categorySkuPaginationAndSearchingState,
        orderPaginationAndSearchingState,
        stocReportPaginationAndSearchingState,
        getProductManagerWithCLientWarehouse,
        clearAllPaginationState,
        client: state.client,
        user: state.user,
        stock: state.stock,
        categories: state.categories,
        products: state.products,
        cardItem: state.cardItem,
        grandtotal: state.grandtotal,
        orders: state.orders,
        error: state.error,
        loading: state.loading,
        msg: state.msg,
        categoryPagination: state.categoryPagination,
        categorySkuPagination: state.categorySkuPagination,
        orderPagination: state.orderPagination,
        stockReportPagination: state.stockReportPagination,
        productManagerWithWarehouse: state.productManagerWithWarehouse,
      }}
    >
      {props.children}{" "}
    </EssityContext.Provider>
  );
};

export default EssityState;
